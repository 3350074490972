import { utcToLocal } from '@/utils/date_n_time';

export default {
  name: 'VideoThumbnail',

  inject: ['shareEnabled'],

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    bordered: {
      type: Boolean,
      default: false,
    },

    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    dateISOString: {
      type: String,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    actionIndex: {
      type: Number,
    },

    videoAction: {
      type: Function,
      default: () => {},
    },

    to: {
      type: [Object, null],
      default: null,
    },
  },

  computed: {
    localDateISOString() {
      const localDate = new Date(utcToLocal(this.dateISOString));
      return localDate.toISOString();
    },
    formattedDate() {
      const localDate = new Date(utcToLocal(this.dateISOString));
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'medium' }).format(localDate);
    },
    durationFormatted() {
      if (!(this.action.videos instanceof Array) || !this.action.videos.length) {
        return '-';
      }

      if (this.action.videos.length === 1) {
        const date = new Date(0);
        date.setSeconds(
          (this.action.videos[0]?.metadata?.PreTime ?? 0) + (this.action.videos[0]?.metadata?.PostTime ?? 0)
        );

        return date.toISOString().substr(15, 4);
      }

      return `${this.action.videos.length} videos`;
    },
  }
};
